import React, {Component} from "react"
import ReactDatePicker from "react-datepicker"
import styles from "./DatePicker.module.css"
import {formatToStringDate} from "lib/functions/helper"

interface DatePickerProps {
    id: string
    json_data: any
    onChange: (id, value) => void
    disabled?: boolean
    classNameWrap?: string
    className?: string
    format?: "year"
}

export class DatePicker extends Component<DatePickerProps, {}> {
    private FORMATS = {
        fullDate: "MM/dd/yyyy",
        year: "yyyy"
    }
    constructor(props) {
        super(props)
    }
    public render() {
        const {
            json_data,
            id,
            onChange,
            classNameWrap,
            disabled = false,
            className,
            format
        } = this.props

        const {
            FORMATS: {fullDate, year}
        } = this

        let value = json_data[id] || ""
        const classRoot = `${styles.root} ${className}`
        let convertValue = value
        if (!convertValue) {
            convertValue = undefined
        } else {
            convertValue = formatToStringDate(convertValue)
        }

        return (
            <div className={`${classNameWrap} DatePicker_wrapper`}>
                <ReactDatePicker
                    id={id}
                    selected={Date.parse(convertValue)}
                    onChange={(date) => {
                        onChange(id, formatToStringDate(date))
                    }}
                    className={classRoot}
                    disabled={disabled}
                    placeholderText={`${format && format === "year" ? year : fullDate}`}
                    dateFormat={`${format && format === "year" ? year : fullDate}`}
                    showYearPicker={format && format === "year" ? true : false}
                    autoComplete="off"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        )
    }
}
