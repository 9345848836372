import moment from "moment"

const ONE_TIME_PASSWORD_TYPES = {
    pin: "pin",
    otp: "otp",
    web: "web",
    webReset: "web-reset",
    signUp: "signup"
}

const formatToStringDate = (date) => {
    if (!date) {
        return ""
    }
    const formattedDate = moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss")
    if (formattedDate && formattedDate.includes("/1969")) {
        return ""
    }
    return formattedDate
}

const DELIMITER = "<<@>>"

const getPartsByDelimiter = (value) => {
    return value.split(DELIMITER)
}

export {formatToStringDate, DELIMITER, getPartsByDelimiter, ONE_TIME_PASSWORD_TYPES}
