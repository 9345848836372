import React, {Component, KeyboardEvent} from "react"
import styles from "./SignIn.module.css"
import {Button} from "./parts/Button"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {Captcha} from "components/Captcha"
import {LoginWrapper} from "components/LoginWrapper"
import {Link} from "react-router-dom"
import {Model} from "../../Model"
import {Authorisation} from "models/Authorisation"
import {observable, toJS} from "mobx"
import {observer} from "mobx-react"
import {ReactComponent as ClockIcon} from "./parts/img/icons/clock.svg"
import {
    CHECK_PASSWORD_OPTIONS,
    findErrors,
    findPasswordErrors
} from "../../lib/functions/findErrors"
import {DatePicker} from "components/DatePicker"
import {LabelPassword} from "components/inputs/LabelPassword"
import {KlassDropdown} from "components/inputs/KlassDropdown"
interface Props {
    model: Model
    auth: Authorisation
}

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    campus_code: mandatory,
    email: {
        email: true
    }
}

const oneTimePassword = {
    otp: mandatory
}

const customPasswordConfirmSignUpRule = {
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    }
}

const customPasswordSignInRule = {
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    }
}

const userRules = {
    first_name: mandatory,
    last_name: mandatory,
    phone_number: mandatory,
    program: mandatory,
    start_date: mandatory,
    captcha_value: mandatory
}

@observer
export class SignIn extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private passwordOptions: any = {
        options: CHECK_PASSWORD_OPTIONS.default
    }

    @observable
    isVisibleOtp: boolean = false

    @observable
    isVisibleMatchPassword: boolean = false

    @observable
    isPrepopulateUserInfo: boolean = false

    @observable
    private user = {
        campus_code: "", // "3"
        email: "", // "wgrueninger@naa.edu",// "testag@yopmail.com",
        password: "",
        confirm_password: "",
        first_name: "", // "aaaa"
        last_name: "", // "bbbb"
        phone_number: "", // 9878178888
        program: "",
        start_date: "",
        captcha_value: ""
    }

    private KEY_CODE = 13
    private KEY = "Enter"

    private recaptchaRef: any = React.createRef()

    private isValidCampusCode = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }

    private isValid = () => {
        const hasUser =
            this.props.model.clientInfo.userInfo && this.props.model.clientInfo.userInfo.isUserExist
        // const newRules = {
        //     ...rules,
        //     ...customPasswordConfirmSignUpRule,
        //     ...userRules
        // }
        let newRules = {
            ...rules
        }
        if (!hasUser) {
            newRules = {
                ...newRules,
                ...customPasswordConfirmSignUpRule,
                ...userRules
            }
        } else {
            newRules = {
                ...newRules,
                ...customPasswordSignInRule,
                ...userRules
            }
        }
        const {isValid, errors} = findErrors(this.user, newRules)
        this.errors = errors
        if (!hasUser) {
            const {isValid: isValidPassword} = this.passwordOptions
            this.isVisibleMatchPassword = !isValidPassword
            return isValid && isValidPassword
        }
        return isValid
    }

    private isValidPassword = () => {
        const {isValid, options} = findPasswordErrors(this.user)
        this.passwordOptions = {
            isValid,
            options
        }
    }

    private onKeyUpPassword = (event: KeyboardEvent<Element>) => {
        this.isValidPassword()
    }

    private isValidConfirmPassword = () => {
        const {isValid, errors} = findErrors(this.user, customPasswordConfirmSignUpRule)
        this.errors = errors
    }

    private onKeyUpConfirmPassword = (event: KeyboardEvent<Element>) => {
        this.isValidConfirmPassword()
    }

    private onChangeCaptchaText = (id: string, value: string) => {
        this.user[id] = value
    }

    private onKeyDownSignIn = (event: KeyboardEvent<Element>) => {
        // event.preventDefault()
        const {which, keyCode, key} = event
        if (which === this.KEY_CODE || keyCode === this.KEY_CODE || key === this.KEY) {
            this.checkUserClientAuth()
        }
    }

    componentDidUpdate(): void {
        //todo: add more prepopulated info here like, [phone], [programs]
        if (
            this.props.model.clientInfo &&
            this.props.model.clientInfo.userInfo.data &&
            !this.isPrepopulateUserInfo
        ) {
            const {firstName, lastName} = this.props.model.clientInfo.userInfo.data
            this.user.first_name = firstName
            this.user.last_name = lastName
            this.isPrepopulateUserInfo = !this.isPrepopulateUserInfo
        }
    }

    public render() {
        const {
            onKeyDownSignIn,
            onChangeCaptchaText,
            backendErrors,
            onKeyUpPassword,
            onKeyUpConfirmPassword
        } = this
        const {model} = this.props
        // console.log('@@@@ this.clientInfo:', toJS(model.clientInfo))
        return (
            <LoginWrapper>
                <div className={styles.root}>
                    <h2 className={styles.title}>
                        Welcome to the <br />
                        Legacy Student Success <br /> System
                        {/* entrance exam! */}
                    </h2>
                    <article className={styles.article}>
                        <div className={styles.articleTitleWrapper}>
                            <h2 className={styles.articleTitle}>FIND YOUR PURPOSE!</h2>
                        </div>
                        <ul className={styles.articleList}>
                            <li className={styles.articleItem}>
                                <ClockIcon className={styles.articleIcon} width="11" height="11" />
                                <p className={styles.articleText}>
                                    This questioner is automatically scored and will provide you
                                    with the results once every section is completed.
                                </p>
                            </li>
                            <li className={styles.articleItem}>
                                <p className={styles.articleText}>
                                    Please login below and follow the instructions.
                                    {/* If you have no questions and are ready to begin,  */}
                                </p>
                            </li>
                            <li className={styles.articleItem}>
                                <p className={styles.articleText}>
                                    <b>Good Luck!</b>
                                </p>
                            </li>
                        </ul>
                    </article>

                    <article className={styles.loginArea}>
                        <h3 className={styles.h3}>LOGIN</h3>
                        <div className={styles.inner}>
                            <Label
                                text="Campus Code"
                                className={styles.inputUser}
                                error={this.errors["campus_code"]}
                                required>
                                <Input
                                    onChange={(v) => {
                                        this.user.campus_code = v
                                    }}
                                    value={this.user.campus_code}
                                    onKeyDown={onKeyDownSignIn}
                                    disabled={this.isVisibleOtp}
                                />
                            </Label>

                            <Label
                                text="Email address"
                                className={styles.inputUser}
                                error={this.errors["email"]}
                                required>
                                <Input
                                    onChange={(v) => {
                                        this.user.email = v
                                    }}
                                    value={this.user.email}
                                    onKeyDown={onKeyDownSignIn}
                                    disabled={this.isVisibleOtp}
                                />
                            </Label>
                            {this.isVisibleOtp && (
                                <React.Fragment>
                                    {model.clientInfo.userInfo.isUserExist ? (
                                        <Label
                                            text="Password"
                                            required
                                            className={styles.inputPass}
                                            error={this.errors["password"]}>
                                            <Input
                                                onChange={(v) => {
                                                    this.user.password = v
                                                }}
                                                value={this.user.password}
                                                type="password"
                                            />
                                        </Label>
                                    ) : (
                                        <React.Fragment>
                                            <LabelPassword
                                                text="Password"
                                                required
                                                className={styles.inputPass}
                                                isVisible={this.isVisibleMatchPassword}
                                                minChars={8}
                                                options={this.passwordOptions.options}>
                                                <Input
                                                    onChange={(v) => {
                                                        this.user.password = v
                                                    }}
                                                    value={this.user.password}
                                                    type="password"
                                                    onKeyUp={onKeyUpPassword}
                                                    onFocus={() =>
                                                        (this.isVisibleMatchPassword = true)
                                                    }
                                                    onBlur={() =>
                                                        (this.isVisibleMatchPassword =
                                                            this.passwordOptions.options.value !==
                                                                CHECK_PASSWORD_OPTIONS.values
                                                                    .strong &&
                                                            this.passwordOptions.options.value !==
                                                                CHECK_PASSWORD_OPTIONS.values
                                                                    .medium)
                                                    }
                                                />
                                            </LabelPassword>

                                            <Label
                                                text="Confirm Password"
                                                required
                                                className={styles.inputPass}
                                                error={this.errors["confirm_password"]}>
                                                <Input
                                                    onChange={(v) => {
                                                        this.user.confirm_password = v
                                                    }}
                                                    value={this.user.confirm_password}
                                                    type="password"
                                                    onKeyUp={onKeyUpConfirmPassword}
                                                />
                                            </Label>
                                        </React.Fragment>
                                    )}

                                    <Label
                                        text="First Name"
                                        className={styles.inputPass}
                                        error={this.errors["first_name"]}
                                        required>
                                        <Input
                                            onChange={(v) => {
                                                this.user.first_name = v
                                            }}
                                            value={this.user.first_name}
                                        />
                                    </Label>

                                    <Label
                                        text="Last Name"
                                        className={styles.inputPass}
                                        error={this.errors["last_name"]}
                                        required>
                                        <Input
                                            onChange={(v) => {
                                                this.user.last_name = v
                                            }}
                                            value={this.user.last_name}
                                        />
                                    </Label>

                                    <Label
                                        text="Phone"
                                        className={styles.inputPass}
                                        error={this.errors["phone_number"]}
                                        required>
                                        <Input
                                            onChange={(v) => {
                                                this.user.phone_number = v
                                            }}
                                            value={this.user.phone_number}
                                        />
                                    </Label>

                                    {/* <Label
                                        text="Year You Graduated from High School or Received Your GED:"
                                        className={styles.inputPass}
                                        error={this.errors["graduation_date"]}>
                                        <Input
                                            onChange={(v) => {
                                                this.user.graduation_date = v
                                            }}
                                            value={this.user.graduation_date}
                                        />
                                    </Label> */}

                                    <Label
                                        text="The Program You Have Enrolled In:"
                                        className={styles.inputPass}
                                        error={this.errors["program"]}
                                        required>
                                        <KlassDropdown
                                            onChange={(v) => {
                                                this.user.program = v.value
                                            }}
                                            options={this.props.model.programsByClient}
                                            value={this.props.model.programsByClient.find((el) => {
                                                return el.value === this.user.program
                                            })}
                                        />
                                    </Label>

                                    <Label
                                        text="Your Start Date:"
                                        className={styles.inputPass}
                                        error={this.errors["start_date"]}
                                        required>
                                        <DatePicker
                                            id={`start_date`}
                                            className="readmode"
                                            json_data={this.user}
                                            onChange={(_, date) => {
                                                this.user.start_date = date
                                                this.forceUpdate()
                                            }}
                                        />
                                    </Label>

                                    <Captcha
                                        recaptchaRef={this.recaptchaRef}
                                        id={`captcha_value`}
                                        user={this.user}
                                        onChange={onChangeCaptchaText}
                                        onKeyDown={onKeyDownSignIn}
                                        errors={this.errors}
                                        backendErrors={backendErrors}
                                    />
                                </React.Fragment>
                            )}

                            <Button
                                text="SIGN IN"
                                onClick={() => {
                                    if (!this.isVisibleOtp) {
                                        this.checkClientCampusCodeAndEmail()
                                    } else {
                                        this.checkUserClientAuth()
                                    }
                                }}
                                className={styles.loginButton}
                            />

                            <Link to="/accounts/recovery" className={styles.link}>
                                I forgot my password
                            </Link>

                            {/* <Link to="/sign-up" className={styles.link}>
                                I do not have an account
                            </Link> */}
                        </div>
                    </article>
                </div>
            </LoginWrapper>
        )
    }

    private async checkClientCampusCodeAndEmail() {
        if (this.isValidCampusCode()) {
            const {email, campus_code} = this.user
            const response = await this.props.model.campusCodeCheck({
                campusCode: campus_code,
                email
            })
            if (response.success) {
                this.isVisibleOtp = true
            } else {
                alert(`${response.message}`)
            }
        }
    }

    private async checkUserClientAuth() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.isValid()) {
            // await this.recaptchaRef.current.executeAsync()
            const user = this.user
            const userInfo = this.props.model.clientInfo.userInfo
            const apiKeys = this.props.model.clientInfo.apiKeys
            const client = this.props.model.clientInfo.client
            const response = await this.props.auth.clientSignInOrSignUp("lsss", {
                ...user,
                skip: false,
                userInfo,
                apiKeys,
                client
            })
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset()
                this.user.captcha_value = ""
            }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }

    private async signIn() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.isValid()) {
            // await this.recaptchaRef.current.executeAsync()
            const user = this.user
            const response = await this.props.auth.signIn("lsss", {...user, skip: false})
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset()
                this.user.captcha_value = ""
            }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }
}
