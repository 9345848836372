import React, {Component} from "react"
import styles from "./Footer.module.css"
import lsssLogo from "../Header/parts/img/logo-lsss.png"

const campuses = [
    {
        id: 1,
        region_id: 1,
        name: "Minnesota",
        code: "Minnesota",
        address: "4014 10th St S. ​Moorhead, MN 56560",
        state: "",
        phone: "1-800-848-8817",
        school_code: "",
        region: {
            id: 1,
            state: "Minnesota",
            region: "Minnesota"
        }
    }
]

const social = [
    {
        id: 1,
        name: "Facebook",
        link: "#",
        icon: "fa fa-facebook"
    },
    {
        id: 2,
        name: "Instagram",
        link: "#",
        icon: "fa fa-instagram"
    },
    {
        id: 3,
        name: "Twitter",
        link: "#",
        icon: "fa fa-twitter"
    },
    {
        id: 4,
        name: "Youtube",
        link: "#",
        icon: "fa fa-youtube-play"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img src={lsssLogo} alt="lsss-logo" className={styles.logo} />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={icon}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>CAMPUS LOCATION</strong>
                        </h5>
                        {campuses.map(({code, address, phone, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
