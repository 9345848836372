import {AxiosInstance} from "axios"
import {EventDispatcher} from "./EventDispatcher"

const axiosModule = require("axios")

export class Gate extends EventDispatcher {
    private axios: AxiosInstance
    private token: string | null = null
    private passwordToken: string | null = null

    constructor() {
        super()
        this.axios = axiosModule.create()
    }

    public setToken(token: string | null) {
        this.token = token
    }

    public getToken(): string | null {
        return this.token
    }

    public async request(urlPath: string, data: any = {}): Promise<GateResponse> {
        let url = urlPath

        url = process.env.REACT_APP_API_DOMAIN + urlPath

        let request: any = {
            method: "post",
            url: url,
            responseType: "json",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        }

        /**
         * If JWT exists in localstorage, adding "Authorization" header
         * with JWT from localstore.
         */
        if (this.token) {
            request.headers.Authorization = `Bearer ${this.token}`
        }

        if (data.method) {
            request.method = data.method
        }

        if (data.data) {
            request.data = data.data
        }

        console.log("-->> " + url, request)

        let errorMessage: string | null = null
        let responseData: any

        try {
            let response = await this.axios(request)
            responseData = response.data
            console.log("<<-- ", responseData)
        } catch (error) {
            if (error.response && error.response.data) {
                errorMessage = error.response.data.errors[0].message
                responseData = error.response.data
                console.log("<<-- ", errorMessage, responseData)
                if (!url.includes("/accounts/check")) {
                    alert(`${errorMessage}`)
                }
            } else {
                // Network level errorMessage. Server is down or something
                errorMessage = error.message
                responseData = {success: false, errors: [{message: errorMessage}]}
                console.log("<<-- ", errorMessage)
                if (!url.includes("/accounts/check")) {
                    alert(`${errorMessage}`)
                }
            }
        }

        this.dispatch(responseData)
        return responseData
    }
}

export interface GateResponse<D = any> {
    success: boolean
    errors?: GateError[]
    data?: D
    meta?: any
}

//TODO Add
// type GateResponse = GateResponse_OK | GateResponse_ERORR

export interface GateError {
    field?: string
    data?: string
    message: string
}
